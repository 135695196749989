
import { reactive } from 'vue'
import _list from '@/assets/js/list'
import _model from '@/static/model/portalCode/batch'

export default {
	components: { },
	setup(props, context) {
		return {
			_model: _model,
			options: reactive({}),
			setting: reactive({
				moduleName: '电子券码',
				searchApi: '/Mar/Market/search',
				moduleType: 'full',
				
			}),
			list: reactive({
				data: [ ]
			}),
			
		}
	},
	mounted() {
		this._load(this, _list)
		this._listSearch()
	},
	methods: {
		
		//切换状态
		changeState: function(id, state) {
			this._confirm('是否'+(state==1?'停用':'启用')+'当前批次？', ()=>{
				this._post('/Mar/Market/changeState', { id: id, state: state==1?3:1}, (res)=>{
					this._msg('操作成功')
					this._listSearch()
				})
			})
		},
		
		
	}	
}
